import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StackDateforDisplay } from '../../actions/app';

import {
    Avatar,
    Button,
    IconButton,
    Box,
    Tooltip,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormLabel,
    TextField,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import EventBusyIcon from '@mui/icons-material/EventBusy';

import { cancelEvent } from '../../actions/event';

const CancelEventBtnDialog = ({
    auth,
    theEvent,
    hostMe,
    artistMe,
    canceledBy,
    cancelEvent,
}) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    // console.log('Today is ', today);
    // console.log('yesterday', yesterday);

    const isFuture = (bookingWhen) => {
        if (new Date(bookingWhen) >= yesterday) {
            // console.log(
            //     'new Date(bookingWhen) is greater than yesterday',
            //     new Date(bookingWhen),
            //     'yesterday',
            //     yesterday
            // );
            return true;
        } else {
            // console.log(
            //     'new Date(bookingWhen) is less than yesterday',
            //     new Date(bookingWhen),
            //     'yesterday',
            //     yesterday
            // );
            return false;
        }
    };

    const isWithin48Hours = (bookingWhen) => {
        const eventDate = new Date(bookingWhen);
        const now = new Date();
        const hoursUntilEvent = (eventDate - now) / (1000 * 60 * 60);
        return hoursUntilEvent <= 48;
    };

    const [cancelEventDialogOpen, setCancelEventDialogOpen] = useState(false);
    const [authorizedHost, setAuthorizedHost] = useState(false);
    const [artistStageName, setArtistStageName] = useState(false);
    const [artistProfileImg, setArtistProfileImg] = useState('');
    const [authorizedArtist, setAuthorizedArtist] = useState(false);
    const [hostProfileImg, setHostProfileImg] = useState('');
    const [canceledReason, setReason] = useState('');

    const cancelEventDialogHandleClose = () => {
        setCancelEventDialogOpen(false);
    };

    const onReasonChange = (e) => {
        setReason(e.target.value);
    };

    useEffect(() => {
        if (
            (theEvent.status === 'CONFIRMED' ||
                theEvent.status === 'PENDING') &&
            canceledBy === 'HOST' &&
            hostMe &&
            hostMe._id === theEvent.confirmedHost
        ) {
            setAuthorizedHost(true);

            let theArtistStageName;
            let theArtistProfileImg;

            if (theEvent.artist) {
                theArtistStageName = theEvent.artist.stageName;
                theArtistProfileImg = theEvent.artist.squareImg;
            } else if (
                theEvent.preferredArtists &&
                theEvent.preferredArtists.length > 0
            ) {
                let theConfirmedArtist = theEvent.preferredArtists.filter(
                    (prefArtist) => {
                        if (
                            theEvent.confirmedArtist &&
                            (theEvent.confirmedArtist === prefArtist._id ||
                                theEvent.confirmedArtist._id === prefArtist._id)
                        ) {
                            console.log('CONFIRMED artist', prefArtist);

                            return prefArtist;
                        }
                    }
                );
                // console.log('theConfirmedArtist', theConfirmedArtist);
                theArtistStageName = theConfirmedArtist[0]
                    ? theConfirmedArtist[0].stageName
                    : '';
                theArtistProfileImg = theConfirmedArtist[0]
                    ? theConfirmedArtist[0].squareImg
                    : '';
            }

            setArtistStageName(theArtistStageName);
            setArtistProfileImg(theArtistProfileImg);
        }
        if (
            (theEvent.status === 'CONFIRMED' ||
                theEvent.status === 'PENDING') &&
            canceledBy === 'ARTIST' &&
            artistMe &&
            artistMe._id === theEvent.confirmedArtist
        ) {
            setAuthorizedArtist(true);
        }
    }, [canceledBy]);

    return (
        <>
            {auth &&
                auth.user &&
                auth.user.role &&
                auth.user.role.indexOf('ADMIN') !== -1 &&
                //feature flag for ADMINs to test, first

                (theEvent.status === 'CONFIRMED' ||
                    theEvent.status === 'PENDING') &&
                isFuture(theEvent.bookingWhen) && //if the event is in the future
                (authorizedArtist || authorizedHost) && ( // and the user is either the Host of the event, or the Artist performing
                    <>
                        <Dialog
                            open={cancelEventDialogOpen}
                            onClose={cancelEventDialogHandleClose}
                            maxWidth="sm"
                            className="porchlightBG"
                        >
                            <DialogContent>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'rgba(0 0 0 /.6)',
                                        padding: '0',
                                        zIndex: 100,
                                        display: {
                                            xs: 'none',
                                            sm: 'block',
                                        },
                                    }}
                                >
                                    <StackDateforDisplay
                                        date={theEvent.bookingWhen}
                                    ></StackDateforDisplay>
                                </Box>
                                <DialogContentText id="alert-dialog-description">
                                    {isWithin48Hours(theEvent.bookingWhen) && (
                                        <Grid
                                            container
                                            sx={{
                                                flexDirection: 'row',
                                                flexWrap: 'nowrap',
                                                alignItems: 'center',
                                                color: 'var(--red)',
                                                textAlign: 'center',
                                                margin: '15px 0',
                                                fontWeight: 'bold',
                                                border: '1px solid var(--red)',
                                                padding: '10px',
                                            }}
                                        >
                                            <Grid item>
                                                <WarningAmberIcon
                                                    sx={{ fontSize: '40px' }}
                                                ></WarningAmberIcon>
                                            </Grid>
                                            <Grid
                                                item
                                                sx={{ paddingLeft: '20px' }}
                                            >
                                                {' '}
                                                Wait! It looks like this event
                                                is less than 48 hours away. Are
                                                you sure you want to cancel?
                                                <br />
                                                <br />
                                                Please contact the other party
                                                directly before completing this
                                                step.
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Avatar
                                        sx={{
                                            width: '150px',
                                            height: '150px',
                                            margin: '0 auto 20px',
                                        }}
                                        src={
                                            canceledBy === 'HOST'
                                                ? artistProfileImg
                                                : theEvent.offersFromHosts[0]
                                                      .host.profileImg
                                        }
                                    />
                                    <Typography
                                        component="h2"
                                        sx={{ textWrap: 'balance' }}
                                    >
                                        You're about to cancel this event <br />
                                        on{' '}
                                        {new Date(
                                            theEvent.bookingWhen
                                        ).toLocaleDateString(undefined, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            timeZone: 'UTC', //fixes timezone issues where users see the date a day off sometimes
                                        })}{' '}
                                        <br />
                                        with{' '}
                                        {authorizedHost
                                            ? artistStageName
                                            : authorizedArtist
                                            ? theEvent.offersFromHosts[0].host
                                                  .firstName +
                                              ' ' +
                                              theEvent.offersFromHosts[0].host
                                                  .lastName
                                            : ''}
                                        .
                                    </Typography>{' '}
                                    <Typography
                                        component={'p'}
                                        sx={{
                                            textAlign: 'center',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        Please explain why you've decided to do
                                        this.
                                    </Typography>
                                    <Grid item xs={12} sx={{ width: '100%' }}>
                                        <TextField
                                            // variant="standard"
                                            name="canceledReason"
                                            multiline
                                            id="canceledReason"
                                            label="I'm canceling this concert, because"
                                            value={canceledReason}
                                            onChange={(e) => onReasonChange(e)}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Typography
                                        component={'p'}
                                        sx={{
                                            textAlign: 'center',
                                            marginTop: '10px',
                                        }}
                                    >
                                        An email will be sent out to let
                                        everyone know you canceled the show.{' '}
                                        <br />
                                        Are you sure you mean to do this?
                                        <br />
                                        (There's no undoing it!)
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setCancelEventDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{ color: 'var(--red)' }}
                                    onClick={() =>
                                        cancelEvent({
                                            _id: theEvent._id,
                                            canceledReason: canceledReason,
                                            canceledBy: canceledBy,
                                        })
                                    }
                                    disabled={!canceledReason}
                                >
                                    <EventBusyIcon
                                        sx={{ marginRight: '5px' }}
                                    ></EventBusyIcon>{' '}
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 20,
                                right: 20,
                            }}
                        >
                            <Tooltip arrow={true} title="Cancel Event">
                                <IconButton
                                    onClick={() => {
                                        setCancelEventDialogOpen(true);
                                    }}
                                    size="large"
                                    sx={{ color: 'var(--red)' }}
                                >
                                    <EventBusyIcon></EventBusyIcon>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                )}
        </>
    );
};

CancelEventBtnDialog.propTypes = {
    theEvent: PropTypes.object.isRequired,
    canceledBy: PropTypes.string.isRequired,
    artistMe: PropTypes.object,
    hostMe: PropTypes.object,
    cancelEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    artistMe: state.artist.me,
    hostMe: state.host.me,
});

// export default CancelEventBtnDialog;
export default connect(mapStateToProps, { cancelEvent })(CancelEventBtnDialog);
